<template>
  <div v-if="show" id="desc" class="platter mb-4">
    <h3 v-if="title" class="mb-3">
      <strong>{{ title }}</strong>
    </h3>
    <p v-for="c in copyArray" :key="c">{{ c }}</p>
  </div>
</template>

<script>
export default {
  name: "VendorDescription",
  props: {
    title: { type: String },
    copy: { type: String }
  },
  computed: {
    show() {
      return this.title || this.copy;
    },
    copyArray() {
      return !this.copy || this.copy.split("\n\n");
    }
  },
  mounted() {
    if (this.show) {
      this.$emit("add-nav", {
        href: "#desc",
        title: "Description",
        icon: "align-left"
      });
    }
  }
};
</script>

<style scoped lang="scss">
p:last-child {
  margin-bottom: 0;
}
</style>